<template>
    <a class="rounded-button" @click="onClick">{{text}}</a>
</template>

<script>
    export default {
        name: "roundedButton",
        props: ['text'],
        methods: {
            onClick(event) {
                this.$emit('buttonClick', event);
            }
        }
    }
</script>

<style scoped>

    .rounded-button {
        border: 1px solid #36CAF5;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 7px;
        padding: 7px;
        color: #36CAF5;
        margin: 2px 15px;
        display: inline-block;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        background: white;
        -webkit-transition: all .3s;
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        height: max-content;
        width: max-content;
    }

    .rounded-button:hover {
        background: #36CAF5;
        color: white;
    }

    .rounded-button a {
        color: #36CAF5;
    }

</style>