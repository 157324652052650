<template>
  <div class="range-slider">
    <span class="title">Safety Levels</span>
    <div class="range">
      <input class="input-range" type="range"
             min="0"
             max="2"
             :value="currentLevel"
             @change="onChange"
             :style="{background: rangeBackground}">

      <div class="levels">
        <span v-for="(level,index) in levels" :key="index">{{ level }}</span>
      </div>
    </div>

    <tooltip class="tip" :tip="safetyTips"></tooltip>
  </div>
</template>

<script>
import Tooltip from "./tooltip";

export default {
  name: 'rangeSlider',
  components: {Tooltip},
  props: ['levels'],
  mounted() {
    this.setDefaultSafetyLevel();
  },
  data() {
    return {
      safetyTips: '<strong>Low</strong> - No intervention in your auto complete suggestions,' +
          '<strong>Moderate</strong> - Blah blah,' +
          '<strong>Strict</strong> - Blah',

      safetyLevels:
          [
            {
              level: 'Low',
              levelNum: 0,
              polyFill: '0%'
            },
            {
              level: 'Moderate',
              levelNum: 1,
              polyFill: '50%'
            },
            {
              level: 'Strict',
              levelNum: 2,
              polyFill: '100%'
            }
          ],
      currentLevel: 0,
      rangeBackground: ''
    }
  },
  methods: {
    setDefaultSafetyLevel() {
      const currentSafetyLevel = this.$cookies.isKey('safetyLevel') ? this.$cookies.get('safetyLevel') : this.setStrict();
      const safetyLevelObj = this.safetyLevels.find(obj => obj.level.toLowerCase().includes(currentSafetyLevel.toLowerCase()));

      if (Object.keys(safetyLevelObj).length) {
        this.currentLevel = safetyLevelObj.levelNum;
      }

      this.setPolyfill(this.safetyLevels[this.currentLevel].polyFill);
    },
    setStrict() {
      this.$root.setCookie('safetyLevel', 'strict');
      this.$root.setCookie('safeSearch', 'active');
      return 'Strict';
    },
    setPolyfill(fillPercentage) {
      this.rangeBackground = `linear-gradient(to right, #36CAF5 0%, #36CAF5 ${fillPercentage}, #ccc 1%, #ccc 100%)`;
    },
    onChange(event) {
      this.currentLevel = event.target.value;
      const currentLevel = this.safetyLevels[this.currentLevel];

      this.setPolyfill(currentLevel.polyFill);
      this.$root.setCookie('safetyLevel', currentLevel.level.toLowerCase());
      this.$root.setCookie('safeSearch', currentLevel.level.toLowerCase() !== 'low' ? 'active' : 'inactive');
    }
  },
}
</script>

<style scoped>
.range-slider {
  position: relative;
  width: 100%;
  text-align: center;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
}

.range-slider .tip {
  align-self: baseline;
}

.range-slider .title {
  font-weight: 500;
  align-self: baseline;
  padding: 6px;
  font-size: 16px;
}

.range-slider .levels {
  display: inline-flex;
  justify-content: space-between;
  width: 90%;
}

.range-slider .levels span {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #404040;
}

.range-slider .input-range {
  -webkit-appearance: none;
  height: 12px;
  width: 90%;
  border-radius: 5px;
  background: #ccc;
  outline: none;
  z-index: 2;
  position: relative;
  margin-top: 13px;
}

.range-slider .input-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #36CAF5;
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}

.range-slider .input-range::-webkit-slider-thumb:hover {
  background: #36CAF5;
}

.range-slider .input-range:active::-webkit-slider-thumb {
  background: #36CAF5;
}

.range-slider .input-range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #40DF9F;
  cursor: pointer;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}

.range-slider .input-range::-moz-range-thumb:hover {
  background: #40DF9F;
}

.range-slider .input-range::-moz-range-thumb {
  background: #40DF9F;
  transition: all 0.25s ease-in-out;
}

</style>