<template>
    <div class="title">
        <span>{{title}}</span>
    </div>
</template>

<script>
    export default {
        name: "tabs-title",
        props: ['title']
    }
</script>

<style scoped>

    .title {
        text-align: center;
    }

    .title span {
        text-align: center;
        font-weight: bold;
        margin: auto;
        font-size: 25px;
        padding: 10px;
    }

</style>