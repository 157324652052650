<template>
  <div class="container">
    <h1 class="text-center">Contact Us</h1>
    <div class="block-content">
      <h3 class="block-title">Help & Support</h3>
      <p>
        Our support and development team are very happy to help you. If you find a bug in our product or have
        technical issues using it, let us know.
      </p>
    </div>
    <a :href="Feedback[$store.getters.ext.vert]" class="btn">Send Us Feedback</a>
  </div>
</template>

<script>
import Feedback  from "@/helpers/feedback";
export default {
  name: "contact",
  data() {
    return {
      Feedback
    }
  }
}
</script>

<style scoped>

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


a, a:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h3 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

img {
  max-width: 100%;
  height: auto;
}

a:hover {
  color: #36caf5;
}

.container {
  width: max-content;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  padding: 40px;
  margin: 25px auto;
  animation-duration: 1s;
  animation-name: fadeInUp;
}


</style>