<template>
  <div class="terms">
    <movie v-if="$store.getters.ext.vert === 'movie'"></movie>
    <music v-if="$store.getters.ext.vert === 'music'"></music>
  </div>
</template>

<script>
import Movie from './movie';
import Music from './music';

export default {
  name: "terms",
  components: {
    Movie,
    Music
  }
}
</script>

<style scoped>

</style>