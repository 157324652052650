import fetch from 'node-fetch';


export default {
    state: {},
    actions: {
        async reportAction({commit, rootState, dispatch}, payload) {
            if (!location.port) {
                const extraParams = payload.extraParams ? '&' + new URLSearchParams(payload.extraParams).toString() : '';
                const ctag = await dispatch('getCTagAction');

                const reportUrl = `https://www.${rootState.rootDomain}/report/wa?action=${payload.action}&ctag=${ctag}${extraParams}`;

                try {
                    await fetch(reportUrl);
                } catch (e) {
                    console.log('reports - reportAction: ', e.message);
                }
            } else {
                console.log('reports - reportAction: Reports are disabled when working locally.')
            }
        },
        getCTagAction({rootState}) {
            const cookies = rootState.cookies;
            if (cookies.isKey('yid') && cookies.isKey(cookies.get('yid') + '_cid')) {
                return cookies.get(rootState.cookies.get('yid') + '_cid');
            }

            return '5056';
        }
    },
    mutations: {},
    getters: {}

}