import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSweetAlert2 from 'vue-sweetalert2';
import ToggleButton from 'vue-js-toggle-button';
import VueCookies from 'vue-cookies';
import LazyLoadDirective from './helpers/lazyLoad.js';
import {getRootDomain} from './helpers/utils.js';


Vue.use(ToggleButton);
Vue.use(VueSweetAlert2);
Vue.use(VueCookies);
Vue.directive('lazyload', LazyLoadDirective);


new Vue({
    router,
    store,
    render: h => h(App),
    created() {
        this.setCookieConfig();
        this.checkIfInstalled();
    },
    methods: {
        setCookieConfig() {
            const isLocal = this.$store.getters.isLocal;
            if (isLocal) {
                Vue.$cookies.config('1y', '/');
                return;
            }
            Vue.$cookies.config('1y', '/', `.${getRootDomain()}`, true, 'none');
        },
        checkIfInstalled() {
            const maxTries = 20;
            let currentTry = 0;

            const interval = setInterval(() => {
                if (!this.$store.getters.isInstalled) {
                    this.sendMessage('isInstalled', null).then(() => {
                        this.$root.sendMessage('getConfig')
                            .then(res => {
                                this.$store.dispatch('setIsInstalled', true);
                                this.$store.dispatch('setExtAction', res.response);
                                clearInterval(interval);

                                if (res.response.extensionName) {
                                    document.title = res.response.extensionName;
                                }
                            });
                    });

                    currentTry++;
                    if (currentTry === maxTries) {
                        this.$store.dispatch('setIsInstalled', false);
                        clearInterval(interval)
                    }
                } else {
                    clearInterval(interval);
                }
            }, 100);
        },
        sendMessage(val, data = null, delay = 0) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    window.postMessage({message: val, data}, '*');
                    window.addEventListener('message', event => {
                        if (event.data && event.data.origin && event.data.origin === 'ext') {
                            window.removeEventListener('message', this.listener);
                            resolve(event.data);
                        }
                    }, false);
                }, delay);
            });
        },
        showToast(message, type = 'success') {
            this.$swal
                .mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 10000,
                    timerProgressBar: true,
                })
                .fire({
                    icon: type,
                    title: message
                })
        },
        setCookie(key, value) {
            this.$cookies.set(key, value);
        }
    }
}).$mount('#app');
