import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import {getRootDomain} from '@/helpers/utils';
import VueSweetAlert2 from 'vue-sweetalert2';

import ext from './modules/ext.js';
import settings from './modules/settings.js';
import general from './modules/general.js';
import report from './modules/report.js';

Vue.use(Vuex);
Vue.use(VueSweetAlert2);

const state = {
    rootDomain: getRootDomain(),
    cookies: VueCookies,
    swal: Vue.swal
}

export default new Vuex.Store({
    state,
    modules: {
        ext,
        settings,
        general,
        report
    }
});

