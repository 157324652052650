<template>
    <div v-if="browser === 'Chrome' && !isInstalled" class="install-wrapper">
        <template>
            <h2>{{product.title}}</h2>
            <a @click="openInstallPage()">Add {{product.name}} to {{browser}}</a>
        </template>
    </div>
</template>

<script>
    import install from '../helpers/install.js';
    import {getBrowser} from '../helpers/utils.js';
    import {mapGetters} from 'vuex';

    export default {
        name: 'install',
        computed: {
            ...mapGetters(['isInstalled'])
        },
        mounted() {
            this.setProduct();
        },
        data() {
            return {
                browser: getBrowser(),
                product: {}
            }
        },
        methods: {
            setProduct() {
                try {
                    this.product = install[this.$store.getters.ext.vert];
                } catch (e) {
                    this.product = {};
                }
            },
            openInstallPage() {
                window.open(this.product.url);
            }
        }

    }
</script>

<style scoped>

    .install-wrapper {
        user-select: none;
        width: 500px;
        margin: auto;
    }


    .install-wrapper a {
        border-radius: 5px;
        padding: 14px 17px;
        width: 200px;
        background: #36CAF5;
        color: white;
        text-decoration: none;
        text-align: center;
        margin: auto;
        font-size: 15px;
        cursor: pointer;
    }

    .install-wrapper img {
        cursor: pointer;
        width: min-content;
        max-width: 185px;
    }

</style>