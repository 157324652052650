<template>
  <div class="options">
    <ul class="top-bar-options">
      <li @click="goToGDPR('terms')" :key="1">Terms & Conditions</li>
      <li @click="goToGDPR('privacy')" :key="2">Privacy Policy</li>
      <li @click="goToGDPR('contact')" :key="3">Contact</li>
      <li @click="goToGDPR('help')" :key="4">Help</li>
    </ul>

    <a v-show="$store.getters.isInstalled" @click="uninstallExt" :key="5">Uninstall</a>
    <a v-show="$store.getters.isInstalled && matchingApp" @click="goMatchingApp" :key="6">{{ matchingApp }}</a>
  </div>

</template>

<script>
import GDPR from '@/helpers/gdpr';
import {mapGetters} from 'vuex';

export default {
  name: 'top-bar-options',
  mounted() {
    this.setMatchingApp();
  },
  data() {
    return {
      GDPR
    }
  },
  computed: {
    ...mapGetters(['matchingApp'])
  },
  methods: {
    setMatchingApp() {
      if (!this.matchingApp.length) {
        const matchingApp = this.$cookies.isKey('rw') ? this.$cookies.get('rw') : '';
        this.$store.dispatch('setMatchingApp', matchingApp);
      }
    },
    goToGDPR(gdpr) {
      const url = GDPR[this.$store.getters.ext.vert][gdpr];
      if (url) {
        return window.open(url);
      }

      if (gdpr !== this.$router.currentRoute.params.gdpr) {
        const vert = this.$store.getters.ext.vert;
        this.$router.push({path: `/${vert}/${gdpr}`});
      }
    },
    goMatchingApp() {
      window.open(this.$store.getters.ext.goRedirectionWebsite, '_self');
    },
    uninstallExt() {
      if (!this.$route.fullPath.includes('uninstall')) {
        const vert = this.$store.getters.ext.vert;
        this.$router.push(`/${vert}/uninstall`);
      }
    }
  }
}
</script>

<style scoped>

.options {
  display: flex;
  align-items: center;
}

.top-bar-options {
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-flow: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  animation-delay: 5s;
}

.top-bar-options li {
  color: black;
  padding: 10px;
  width: max-content;
  font-size: 11px;
  display: inline-block;
  font-weight: normal;
  cursor: pointer;
  border-radius: 4px;
  animation-duration: 1s;
  animation-name: fadeInUp;
}

.options a {
  color: black;
  padding: 10px;
  width: max-content;
  font-size: 11px;
  display: inline-block;
  font-weight: normal;
  cursor: pointer;
  border-radius: 4px;
  animation-duration: 1s;
  animation-name: fadeInUp;
}

.top-bar-options li:nth-child(1) {
  animation-delay: .08s;
}

.top-bar-options li:nth-child(2) {
  animation-delay: .1s;
}

.top-bar-options li:nth-child(3) {
  animation-delay: .12s;
}

.top-bar-options li:nth-child(4) {
  animation-delay: .15s;
}

.top-bar-options li:nth-child(5) {
  animation-delay: .17s;
}


.top-bar-options li:hover {
  background: #6bb8e896;
  color: white;
}


.slide {
  transition: all 0.5s;

}

.slide-enter, .card-leave-to
  /* .card-leave-active for <2.1.8 */
{
  opacity: 0;
  transform: scale(0);
}

.slide-enter-to {
  opacity: 1;
  transform: scale(1);
}

.slide-leave-active {
  /*position: absolute;*/
}

.slide-move {
  opacity: 1;
  transition: all 0.5s;
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 60%, 0);
    transform: translate3d(0, 60%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

</style>