<template>
  <div class="not-found">
    <img :src="require('../assets/img/404.png')" alt="">
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

.not-found {
  text-align: center;
}

.not-found img {
  height: 660px;
  width: 800px;
}
</style>