<template>
  <div class="uninstall">
    <div class="wrapper">
      <span class="title">How to Uninstall</span>
      <p class="desc">You can remove our extension from your browser at any time. We are very sorry to see you leave. If you can
        share with us the reason why you want to uninstall our extension, it will really help our product improve in the
        future!</p>

      <img :src="require('../assets/img/remove-extension.svg')" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Uninstall"
}
</script>

<style scoped>

.uninstall {
  user-select: none;
  padding-top: 14vh;
  text-align: center;
  animation-duration: 1s;
  animation-name: fadeInUp;
}

.wrapper {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

}

.title {
  font-size: 40px;
  font-weight: 600;
}

.desc {
  width: 70%;
}

img {
  border: 1px solid lightgray;
  border-radius: 10px;
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>