export default [
    {
        name: 'Default',
        value: 'Yahoo'
    },
    {
        name: 'Yahoo',
        value: 'Yahoo'
    },
    {
        name: 'Bing',
        value: 'Bing'
    },
    {
        name: 'Google',
        value: 'Google'
    }
]