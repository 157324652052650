<template>
    <div class="incognito">
        <div class="content">
            <img :src="require('../assets/img/incognito.svg')" alt="">

            <span class="title">Incognito</span>
            <span class="desc">Your search results were opened in Incognito</span>
            <rounded-button class="button" text="Learn more" @buttonClick="goBack"></rounded-button>
        </div>

    </div>
</template>

<script>
    import RoundedButton from '../components/roundedButton';

    export default {
        name: 'Incognito',
        components: {RoundedButton},
        methods: {
            goBack() {
                this.$router.push(`/${this.$route.params.vert}`, () => {
                    location.reload();
                });
            }
        }
    }
</script>

<style scoped>

    .incognito {
        text-align: center;
        background: gray;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .incognito .content {
        position: relative;
        margin-right: 250px;
    }

    .incognito .content .title {
        font-size: 55px;
        font-weight: bold;
        display: block;
    }

    .incognito .content .desc {
        display: block;
        font-size: 18px;
        margin-bottom: 5px;
        max-width: 330px;
    }

    .incognito img {
        position: absolute;
        height: 660px;
        width: 800px;
        top: -250px;
        left: 20px;
    }
</style>