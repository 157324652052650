import {getBrowser} from "../../helpers/utils.js";


export default [
    {
        title: 'How to use',
        description: `Activate through your ${getBrowser()} search`,
        icon: 'question'
    },
    {
        title: 'Info',
        description: 'Get to know us better',
        icon: 'info'
    },
    {
        title: 'Settings',
        description: 'Control your Genie from here',
        icon: 'cog'
    }
];