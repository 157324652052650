<template>
  <div class="gdpr">
    <help v-if="gdpr === 'help'"></help>
    <terms v-if="gdpr === 'terms'"></terms>
    <privacy v-if="gdpr === 'privacy'"></privacy>
    <contact v-if="gdpr === 'contact'"></contact>
  </div>
</template>

<script>
import Help from "@/components/gdpr/help";
import Contact from "@/components/gdpr/contact";
import Terms from "@/components/gdpr/terms/terms";
import Privacy from "@/components/gdpr/privacy/privacy";

export default {
  name: "GDPR",
  components: {
    Privacy,
    Contact,
    Terms,
    Help
  },
  created() {
    this.gdpr = this.$router.currentRoute.params.gdpr || 'help';
  },
  data() {
    return {
      gdpr: ''
    }
  },
  watch: {
    '$route': function (router) {
      this.gdpr = router.params.gdpr;
    }
  }
}
</script>

<style scoped>

.gdpr {
  margin-top: 70px;
}

</style>