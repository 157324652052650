<template>
    <div class="examples">
        <div class="title">
            <span>{{questions.title}}</span>
        </div>
        <div class="content">
            <div class="gif-example">
                <video playsinline loop muted autoplay>
                    <source :src="setExample()">
                </video>
                <span>Example</span>
            </div>
            <ul>
                <li class="box" v-for="question in questions.examples" :key="question">
                    <p>{{question}}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Questions from '../../helpers/questions.js';

    export default {
        name: 'examples',
        data() {
            return {
                questions: Questions[this.$store.getters.ext.vert] || []
            }
        },
        methods: {
            setExample() {
                try {
                    return require(`../../assets/vid/example/${this.$store.getters.ext.vert}.mp4`);
                } catch (e) {
                    return require('../../assets/vid/example/typing-example.mp4');
                }
            }
        }
    }
</script>

<style scoped>
    .examples {
        text-align: center;
    }

    .examples .title {
        margin: auto;
        padding: 5px;
    }

    .examples .title span {
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        max-width: 50%;
    }

    .examples .content {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
    }

    .examples .content .box {
        display: -ms-inline-flexbox;
        display: block;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 215px;
        margin: 5px;
        border: 1px solid lightgray;
        padding: 6px 17px 6px 17px;
        border-radius: 15px;
        list-style-type: none;
    }

    .examples .content .box p {
        user-select: text;
        text-align: center;
        margin: 0;
        font-size: 14px;
    }

    .examples .content .gif-example {
        position: relative;
    }

    .examples .content .gif-example span {
        position: absolute;
        bottom: 7px;
        right: 0;
        left: 0;
        background: #cecece66;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .examples .content .gif-example video {
        border: 1px solid lightgray;
        box-shadow: 0 1px 8px 0 lightgrey;
        border-radius: 4px;
        max-width: 285px;
    }



</style>
