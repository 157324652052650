export default {
    movie: {
        terms: 'https://www.moviesearchapp.net/terms',
        privacy: 'https://www.moviesearchapp.net/privacy',
        contact: 'https://www.moviesearchapp.net/contact',
        help: 'https://www.moviesearchapp.net/#faqs'
    },
    music: {
        terms: 'https://www.musicsearchapp.net/terms',
        privacy: 'https://www.musicsearchapp.net/privacy',
        contact: 'https://www.musicsearchapp.net/contact',
        help: 'https://www.musicsearchapp.net/#faq'
    }
}