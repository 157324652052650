<template>
  <div class="tab-frame-wrapper">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "menu-frame",
}
</script>

<style scoped>
.tab-frame-wrapper {
  height: inherit;
  width: 780px;
  max-width: 780px;
  min-height: 450px;
  margin-right: 40px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 4px 1px lightgray;
  animation-duration: 1s;
  animation-name: fadeInUp;
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

</style>
