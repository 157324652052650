<template>
    <div class="tooltip" v-if="computedTip instanceof Array">?
        <span class="tooltiptext multiple-tips">
            <span v-for="(tip, index) in computedTip" :key="index" v-html="tip">{{tip}}</span>
        </span>
    </div>
    <div class="tooltip" v-else>?
        <span class="tooltiptext">{{computedTip}}</span>
    </div>
</template>

<script>
    export default {
        name: "tooltip",
        props: ['tip'],
        computed: {
            computedTip: function () {
                if (this.tip.includes(',')) {
                    return this.tip.split(',');
                }
                return this.tip;
            }
        }
    }
</script>

<style scoped>

    .tooltip {
        position: relative;
        display: inline-block;
        cursor: pointer;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        text-align: center;
        font-family: Roboto;
        line-height: 23px;
        border: 0.06em solid lightgray;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        background-color: #36CAF5;
        color: #fff;
        width: max-content;
        text-align: center;
        border-radius: 6px;
        font-size: 13px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: -4px;
        left: 135%;
    }

    .tooltip .multiple-tips {
        display: grid;
        text-align: left;
    }

    .tooltip .multiple-tips:after {
        top: 20% !important;
    }

    .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #36CAF5 transparent transparent;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    @keyframes shake {
        0% {
            transform: rotate(2deg);
        }
        50% {
            transform: rotate(-3deg);
        }
        70% {
            transform: rotate(3deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }

    .tooltip:hover {
        animation: shake 500ms ease-in-out forwards;
    }

</style>