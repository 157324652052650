export default {
    state: {},
    actions: {
        changeSearchEngineAction({rootState, dispatch}, payload) {
            rootState.cookies.set('se', payload.selected.name.toLowerCase());

            dispatch('reportAction', {
                action: 'dashboard_se_change',
                extraParams: {
                    extraString1: payload.selected.name.toLowerCase(),
                    extraBoolean1: !payload.showToast // If the user doesn't have 'se' cookie, we change the default search back to yahoo
                }
            });

            if (payload.showToast) {
                dispatch('showToastAction', {text: `Search Engine was changed to ${payload.selected.name}`})
            }
        },
        changeMatchingAppAction({rootState, dispatch}, payload) {
            rootState.cookies.set('rw', payload.selected.name);
            dispatch('setMatchingApp', payload.selected.name);

            dispatch('reportAction', {
                action: 'dashboard_ma_change',
                extraParams: {
                    extraString1: payload.selected.name.toLowerCase(),
                    extraBoolean1: !payload.showToast
                }
            });

            if (payload.showToast) {
                dispatch('showToastAction', {text: `Matching App was changed to ${payload.selected.name}`})
            }
        },
        toggleAutoCompleteAction({rootState, dispatch}, payload) {
            rootState.cookies.set('use_ac', payload.hasAutoComplete);

            dispatch('reportAction', {
                action: 'dashboard_auto_complete',
                extraParams: {
                    extraBoolean1: payload.hasAutoComplete,
                }
            });
        },
    },
    mutations: {},
    getters: {}
}
