<template>
    <div>
        <div class="tabs">
            <ul>
                <li v-for="(tab,index) in tabs" :class="{ 'is-active': tab.isActive }" :key="index">
                    <a @click="selectTab(tab)">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tabs-details">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "tabs",
        data() {
            return {tabs: []};
        },
        created() {
            this.tabs = this.$children;
        },
        methods: {
            selectTab(selectedTab) {
                this.tabs.forEach(tab => {
                    tab.isActive = (tab.name == selectedTab.name);
                });
            }
        }
    }
</script>

<style scoped>

    .tabs:not(:last-child) {
        margin-bottom: 5px;
    }

    .tabs ul {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-bottom: 1px solid #dbdbdb;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        flex-grow: 1;
        flex-shrink: 0;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        list-style: none;
        margin: 5px auto;
    }

    .tabs li {
        display: block;

    }

    .tabs a {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-bottom: 1px solid #dbdbdb;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: -1px;
        padding: 5px 25px;
        vertical-align: top;
        color: black;
        cursor: pointer;
        text-decoration: none;
        -webkit-transition: none 86ms ease-out;
        transition: none 86ms ease-out;
    }

    .tabs a:hover {
        border-bottom-color: #363636;
        color: #363636;
    }


    .tabs li.is-active a {
        border-bottom-color: #36CAF5;
        color: #36CAF5;
    }

</style>