export default {
    pdf: {
        title: 'File Conversions, simplified.',
        name: 'File Conversions for Chrome',
        url: 'https://chrome.google.com/webstore/detail/lklphhniclmakihfgnmnceimlkfkklmc'
    },
    movie: {
        title: 'Movies, simplified.',
        name: 'Movies for Chrome',
        url: 'https://chrome.google.com/webstore/detail/bbgeecckghpfjgpoffaeilpdbjgdfpjj'
    },
    music: {
        title: 'Music, simplified.',
        name: 'Music for Chrome',
        url: 'https://chrome.google.com/webstore/detail/eaabmejfmdeoaabmealmmbjdjaojakka'
    },
    private: {
        title: '',
        name: 'Search Guard',
        url: 'https://chrome.google.com/webstore/detail/ankpbfodphilmjbepillaoggimgjepcm'
    },
    incognito: {
        title: 'Privacy, simplified.',
        name: 'Secured Browse for Chrome',
        url: 'https://chrome.google.com/webstore/detail/ankpbfodphilmjbepillaoggimgjepcm'
    },
    safe: {
        title: 'Safety, simplified.',
        name: 'AI Safe Search',
        url: 'https://chrome.google.com/webstore/detail/ankpbfodphilmjbepillaoggimgjepcm'
    }
}