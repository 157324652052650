<template>
    <div :class="[$store.getters.ext.vert]">
        <div class="content">
            <TabFrame>
                <template v-slot:content>
                    <HowToUse v-show="activeTab === 1"/>
                    <InfoLinks v-show="activeTab === 2"/>
                    <Settings v-show="activeTab === 3"/>
                </template>
            </TabFrame>
            <TabsList v-if="this.$route.query.tab !== '3'"/>
        </div>
    </div>
</template>

<script>
    import TabsList from '../components/menu-list';
    import TabFrame from '../components/menu-list/menu-frame';
    import InfoLinks from '../components/info';
    import Settings from '../components/settings';
    import HowToUse from '../components/how-to-use';
    import {mapGetters} from 'vuex'

    export default {
        name: 'Main',
        components: {
            TabsList,
            TabFrame,
            InfoLinks,
            Settings,
            HowToUse
        },
        mounted() {
            this.$store.dispatch('reportAction', {
                action: 'dashboard_pageload'
            });
        },
        data() {
            return {
                browser: 'Chrome'
            }
        },
        computed: mapGetters(['activeTab']),
        watch: {
            activeTab: function (currentTab) {
                if (currentTab === 3) {
                    this.$store.dispatch('reportAction', {
                        action: 'dashboard_settings_pageload'
                    });
                }
            }
        }
    }
</script>

<style scoped>

    .content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 14vh;
        margin: auto;
    }

</style>
