<template>
    <div class="tab" v-show="isActive">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "tab",
        props: ['name', 'selected'],
        data() {
            return {
                isActive: false
            }
        },
        mounted() {
            this.isActive = this.selected;
        }
    }
</script>

<style scoped>

    .tab {
        margin: auto;
        overflow: auto;
        max-height: 330px;
    }
</style>