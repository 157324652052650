export default {
    pdf: {
        storeText: `is a personal helper and file launcher right inside your chrome browser
                <br>
                <br>
                <strong>How does it work?</strong>
                <br>
                <br>
                Our AI checks your typing intent. Once it recognizes that these words are related to file conversion, we
                simply offer you a quick links to convert your file, and all this happens right inside your browser auto
                complete suggestions.
                <br>
                <br>
                Yes, it's that easy.
                <br>
                No more looking for the right app or searching for online tools.
                <br>
                AppGenie will know when to suggest the right app or feature to quickly answer your
                file converting need.
                <br>
                <br>
                Here's a short example:
                <br>
                <strong>1.</strong> You decide you want to convert a PDF file to a Word
                <br>
                <strong>2.</strong> You search for "convert PDF to Word"
                <br>
                <strong>3.</strong> Alongside search related suggestions we will offer you with a quick link to convert your file
                <br>
                <strong>4.</strong> Click it and convert your file
                <br>
                <strong>5.</strong> File converted.
                <br>`
    },
    music: {
        storeText: `Music Search for Chrome is a personal helper and app launcher right inside your chrome browser.
                <br>
                <br>
                <strong>How does it work?</strong>
                <br>
                <br>
                Our AI checks your search intent and once we identify a match we simply offer you quick links to search and play music on the most popular sites - All that right inside your auto complete suggestions.
                <br>
                <br>
                Yes, it's that easy. 
                <br>
                No more looking for the right app or searching for online tools. AppGenie will know when to suggest the right app or feature to quickly answer your music search needs.
                <br>
                <br>
                Here's a short example:
                <br>
                <strong>1.</strong> You decide you want to search for "Taylor Swift" on youtube.
                <br>
                <strong>2.</strong> You start typing up "Taylor.."
                <br>
                <strong>3.</strong> Our AI spots your intent for Taylor Swift's music and alongside search related suggestions, it will offer you a quick link to a Taylor Swift Results page as well as popular music sites - such as youtube.com.
                <br>
                <strong>4.</strong> Click it for quick access
                <br>`
    },
    private: {
        storeText: `Search Guard protects your privacy by offering a private search engine whenever 
        <br>
        you type a privacy-related query in your address bar.
        <br>
        <br>
        People are concerned about their private information, but they don’t always know how to protect it.
        <br>
        Our extension detects privacy-related terms that you entered in the browser’s address bar
        <br>
        and will generate a quick link for you to look up the same term in a private search engine. 
        <br>
        <br>
        For example, you want to look for information related to your pension fund.
        <br>
        When you type the term “my pension” in the address bar, at the bottom of the results of a few autocomplete suggestions,
        <br>
        a link is provided to you to lead you to look up the same term at a private search engine. 
        <br>
        To provide the feature of this extension, your default search engine setting will be modified.
        <br>
        <br>
        We provide three options for you to select from:
        <br>
        MyPrivateSearch (default), DuckDuckGo, and Startpage.`
    },
    incognito: {
        storeText: `Secured Browse for Chrome offers you an easily accessible option to search personal
         <br>
         and sensitive information in an Incognito mode.
         <br>
         <br>
          Search queries reveal a tremendous amount about ourselves.
         <br>
         Advertisers then use this information to tailor advertisements to us.
         <br>
         However, we don’t always want our private questions to become targeted advertising.
         <br>
         This extension will remind you when you enter a search query that looks like it might be something you would want to keep private; and
         offers you an easily accessible way to continue your search in incognito mode.
         <br>
         <br>
          For example, you might look for information about a health issue.
         <br>
         This is not something you want to be remembered and personally targeted for advertisements.
         <br>
         <br>
         Add our extension to your browser and when you type personal or sensitive terms in your browser’s address bar,
         our extension will recognize them and provide you with a quick access link to continue your
         search in Incognito mode.
         <br>
         <br>
         If you want to remove our extension, go to “Settings” on Chrome, and find our extension in
         the “Extensions” tab. Click “remove”.`
    },
    safe: {
        storeText: 'Working on it'
    },
    movie: {
        storeText: `Movies Search for Chrome is a personal helper and app launcher right inside your Chrome browser that provides you with quick links to search and play your favorite movies and videos.
                    <br>
                    Start a brand new way of discovering digital content with the help of our AI algorithm.
                    <br>
                    Movies for Chrome knows when to suggest to you the right app or tool and quickly answers your need for the search.
                    <br>
                    <br>
                    In order to get all these features, our extension will change your default search settings.
                    <br>
                    <br>
                    AppGenie’s AI follows your search intent and when it identifies a match, it automatically offers you quick links to search and play the movie on the most popular sites.
                    <br>
                    The whole process takes place inside the AutoComplete textbox of the address bar.
                    <br>
                    <br>
                    <strong>How does it work?</strong>
                    <br>
                    Click on the address bar
                    <br>
                    Start typing your search
                    <br>
                    Once Movies for Chrome finds a relevant result that matches your search, it will offer a quick link at the bottom of your search result
                    <br>
                    <br>
                    <strong>Example:</strong>
                    <br>
                    <strong>1.</strong> Searching for "Romeo and Juliet"
                    <br>
                    <strong>2.</strong> Start typing "Romeo.."
                    <br>
                    <strong>3.</strong> Our AI will recognize that you are looking for the epic film Romeo and Juliet.
                    <br>
                    In addition to regular search results, the extension will offer you a quick link to a results page of all the different versions of it on the most popular film sites, such as IMDb, Rotten Tomatoes, Reelgood, and JustWatch.
                    <br>
                    <strong>4.</strong> Click on it and enjoy the epic love story.
                    `
    }
}