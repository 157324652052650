<template>
  <div class="how-to-use-wrapper">
    <div v-if="!noVideoText.length" class="video-wrapper" v-lazyload>
      <video ref="videoTutorial" :data-url="setVerticalVideo()" playsinline autoplay loop muted controls></video>
    </div>

    <span v-else class="unavailable">{{ noVideoText }}</span>
  </div>
</template>

<script>
export default {
  name: 'how-to-use',
  data() {
    return {
      noVideoText: ''
    }
  },
  methods: {
    setVerticalVideo() {
      const vertical = this.$store.getters.ext.vert;
      try {
        return require(`../assets/vid/tutorials/${vertical}.mp4`);
      } catch (e) {
        console.log('error loading video: ', e);
        return this.noVideoText = 'Video is currently unavailable.';
      }
    }
  }
}
</script>

<style scoped>

.how-to-use-wrapper {
  height: 100%;
  width: 100%;
  padding-top: 5px;
}

.how-to-use-wrapper .unavailable {
  display: inline-flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.video-wrapper {
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  max-width: inherit;
}

.video-wrapper video {
  width: 780px;
  border-radius: 10px;
  position: relative;
  outline: none;
}


</style>
