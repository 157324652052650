export default {
    state: {
        ext: {
            vert: '',
            extensionName: '',
            yid: null,
            extid: null
        }
    },
    actions: {
        setExtAction({commit}, payload) {
            if (payload instanceof Object) {
                commit('setExt', payload)
            }
        },
        setVertAction({commit}, payload) {
            commit('setVert', payload);
        }
    },
    mutations: {
        setExt(state, payload) {
            state.ext = payload;
        },
        setVert(state, payload) {
            state.ext.vert = payload;
        }
    },
    getters: {
        ext: state => {
            return state.ext;
        }
    }
}