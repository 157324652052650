export default {
    pdf: {
        title: 'Here are some examples our AI will detect and offer you to convert them in your chosen app',
        examples: [
            'How do I convert my JPG to PDF? ',
            'Transform DOC to PDF',
            'Can I turn my PDF file to JPG?',
            'PDF conversion types',
            'Change my Power Point to PDF',
            'JPEG to PNG conversion'
        ]
    },
    music: {
        title: 'Here are some examples our AI will detect and offer you to search them in your chosen app',
        examples: [
            'Michael Jackson thriller',
            'Taylor Swift latest album',
            'Nothing else matters',
            'Drake',
            'Top youtube playlists',
            'Country music for the soul'
        ]
    },
    private: {
        title: 'Here is an example of how a search term containing personal or sensitive keyword activates the link for you to search the same keyword in the Incognito mode of your default search engine:',
        examples: [
            'Banking',
            'Finance',
            'Password',
            'Medical Record',
            'Legal'
        ]
    },
    incognito: {
        title: 'Here is an example of how a search term containing personal or sensitive keyword activates the link for you to search the same keyword in the Incognito mode of your default search engine:',
        examples: [
            'Adult',
            'Guns and Weapons',
            'Tobacco',
            'Health Issues',
            'Other keywords indicating personal or sensitive search terms'
        ]
    },
    safe: {
        title: 'Here is an example of how a search query containing privacy-related keyword will activate the quick link for you to search the same term in a private search engine:',
        examples: [
            'Adult content',
            'Guns',
            'CBD and smoking',
            'Violence',
            'Car Accidents'
        ]
    },
    movie: {
        title: 'Here are some examples our AI will detect and offer you to search them in your chosen app',
        examples: [
            'Top rated movies & TV shows',
            'Top rated actors &  actresses',
            'Famous movie directors',
            'Movies from world cinema'
        ]
    }
};