<template>
  <div class="wrapper">
    <section class="content">
      <div class="container">
        <div class="block">
          <h1>Help Center</h1>
          <div class="tab-block active">
            <a class="accordion-toggle" @click="setActiveTab(1)">How to disable our Chrome extension?</a>
            <div class="accordion-content" :class="{active: activeTab === 1}">
              <ol>
                <li>Click on the three horizontal dots icon in the upper right-hand corner of your Chrome browser</li>
                <li>Go to “More tools”.
                  <img src="../../assets/img/gdpr/help/1.jpg" alt="">
                </li>
                <li>In the menu that appears click on "Extensions".
                  <img src="../../assets/img/gdpr/help/2.jpg" alt="">
                </li>
                <li>From the list of installed Extensions, find the name of the extension you wish to disable.</li>
                <li>Click on the blue toggle on the extension’s bottom right corner.
                  <img src="../../assets/img/gdpr/help/3.png" alt="">
                </li>
                <li>Our extension should be disabled. if not, close your browser completely and reopen it.</li>
              </ol>
            </div>
            <a v-if="!isInstalled" class="accordion-toggle" @click="setActiveTab(2)">How do I Change my default Search?</a>
            <div class="accordion-content" :class="{active: activeTab === 2}">
              <ol>
                <li>Click on the three horizontal dots icon in the upper right-hand corner of your Chrome browser.</li>
                <li>Then click on Settings from the drop down menu.</li>
                <li>Use the drop down menu in the Search section of the page to select your desired search provider.
                </li>
              </ol>
            </div>
            <a class="accordion-toggle" @click="setActiveTab(3)">How to uninstall our Chrome extension?</a>
            <div class="accordion-content" :class="{active: activeTab === 3}">
              <ol>
                <li>Click on the three horizontal dots icon in the upper right-hand corner of your Chrome browser.</li>
                <li>Go to “More tools”.
                  <img src="../../assets/img/gdpr/help/4.jpg" alt="">
                </li>
                <li>In the menu that appears click on "Extensions".
                  <img src="../../assets/img/gdpr/help/5.jpg" alt="">
                </li>
                <li>From the list of installed Extensions, find the name of the extension you wish to uninstall.</li>
                <li>Click on the remove button at the extension’s bottom bar.
                  <img src="../../assets/img/gdpr/help/6.png" alt="">
                </li>
                <li>Our extension should be removed from your Chrome browser, if not Close your browser completely and
                  reopen it.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "movie",
  data() {
    return {
      activeTab: 0
    }
  },
  methods: {
    setActiveTab(tab) {
      if (this.activeTab === tab) {
        return this.activeTab = 0;
      }
      this.activeTab = tab;
    }
  },
  computed: {
    ...mapGetters({isInstalled: 'isInstalled'})
  }
}

</script>

<style scoped>

.wrapper {
  animation-duration: 1s;
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

svg {
  display: block;
}

a,
a:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h3 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  cursor: pointer;
  user-select: none;
}

a:hover {
  color: #36caf5;
}

.logo img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 100px;
  -webkit-transform: translateZ(0);
  cursor: pointer;
}

.container {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
}

.block {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  padding: 40px;
}

.accordion-toggle {
  display: block;
  color: #0b0b0b;
  border-radius: 3px;
  margin: 15px 0;
  font-size: 18px;
}


.accordion-content,
.tab-block {
  display: none;
  transition: all .3s ease-in-out;
}

.accordion-content.active,
.tab-block.active {
  display: block;
}

.tab-block {
  margin-top: 30px;
}

.tab-block img {
  display: block;
}

</style>