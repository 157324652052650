<template>
    <div class="info">
        <tabs-title title="About us"></tabs-title>
        <tabs>
            <tab class="how-it-works" name="How it works" :selected="true">
                <span v-html="howItWorks">{{howItWorks}}</span>
            </tab>
            <tab name="Show example">
                <examples></examples>
            </tab>
        </tabs>
    </div>

</template>

<script>
    import Tabs from './tabs/tabs';
    import Tab from './tabs/tab';
    import Examples from './examples/examples';
    import TabsTitle from './tabs/tab-title';
    import HowItWorks from '../helpers/howItWorks.js';


    export default {
        name: 'info',
        components: {TabsTitle, Examples, Tab, Tabs},
        data() {
            return {
                howItWorks: this.initStoreText()
            }
        },
        methods: {
            initStoreText() {
                try {
                    return HowItWorks[this.$store.getters.ext.vert].storeText;
                } catch (e) {
                    return 'Info is currently unavailable.';
                }
            }
        }
    }
</script>

<style scoped>
    .info {
        user-select: none;
        padding: 7px;
    }

    .info .how-it-works {
        padding: 5px;
    }


</style>
