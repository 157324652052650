export default {
    pdf: [
        {
            name: 'Soda PDF'
        },
        {
            name: 'Online Convert'
        },
        {
            name: 'Convert.Files'
        },
        {
            name: 'Convertio'
        },
        {
            name: 'Zamzar'
        },
        {
            name: 'FileZigZag'
        }
    ],
    music: [
        {
            name: 'YouTube'
        },
        {
            name: 'Spotify'
        },
        {
            name: 'Apple Music'
        },
        {
            name: 'Deezer'
        }
    ],
    movie: [
        {
            name: 'Rotten Tomatoes'
        },
        {
            name: 'IMDb'
        },
        {
            name: 'Reelgood'
        },
        {
            name: 'JustWatch'
        }
    ],
    incognito: [
        {
            name: 'Incognito'
        }
    ],
    private: [
        {
            name: 'My Private Search'
        },
        {
            name: 'DuckDuckGo'
        },
        {
            name: 'Startpage'
        }
    ],
    safe: []
}


