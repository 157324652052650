export default {
    state: {
        rootDomain: '',
        isInstalled: null,
        activeTab: 1,
        matchingApp: '',
        isLocal: (window.location.protocol === 'http:' && window.location.port.length > 0)
    },
    actions: {
        setRootDomain({commit}, payload) {
            commit('setRootDomain', payload);
        },
        setIsInstalled({commit}, payload) {
            commit('setIsInstalled', payload);
        },
        setActiveTab({commit}, payload) {
            commit('setActiveTab', payload);
        },
        setMatchingApp({commit}, payload) {
            commit('setMatchingApp', payload);
        },
        showToastAction({rootState}, payload) {
            rootState.swal
                .mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 10000,
                    timerProgressBar: true,
                })
                .fire({
                    icon: payload.type || 'success',
                    title: payload.text
                })
        }
    },
    mutations: {
        setRootDomain(state, payload) {
            state.rootDomain = payload;
        },
        setIsInstalled(state, payload) {
            state.isInstalled = payload;
        },
        setActiveTab(state, payload) {
            state.activeTab = payload;
        },
        setMatchingApp(state, payload) {
            state.matchingApp = payload;
        }
    },
    getters: {
        rootDomain: state => {
            return state.rootDomain;
        },
        isInstalled: state => {
            return state.isInstalled;
        },
        activeTab: state => {
            return state.activeTab;
        },
        matchingApp: state => {
            return state.matchingApp;
        },
        isLocal: state => {
            return state.isLocal;
        }
    }
}