import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue';
import PageNotFound from '../views/PageNotFound.vue';
import Incognito from '../views/Incognito.vue';
import GDPR from '../views/GDPR.vue';
import Removed from '../views/Removed.vue';
import Uninstall from '../views/Uninstall.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        name: '404',
        component: PageNotFound
    },
    {
        path: '/:vert',
        component: Main,
        beforeEnter: (to, from, next) => {
            function isValid() {
                const supportedVerticals = ['pdf', 'music', 'movie', 'private', 'incognito', 'safe'];
                return supportedVerticals.includes(to.params.vert);
            }

            if (!isValid()) {
                next({name: '404'});
            }

            next();
        }
    },
    {
        path: '/:vert/incognito',
        component: Incognito,
        beforeEnter: (to, from, next) => {
            function isPrivate() {
                return to.params.vert === 'private' || to.params.vert === 'incognito';
            }

            if (!isPrivate()) {
                next({name: '404'});
            }

            next();
        }
    },
    {
        path: '/:vert/removed',
        component: Removed,
        beforeEnter: (to, from, next) => {
            function isValid() {
                const supportedVerticals = ['pdf', 'music', 'movie', 'private', 'incognito'];
                return supportedVerticals.includes(to.params.vert);
            }

            if (!isValid()) {
                next({name: '404'});
            }

            next();
        }
    },
    {
        path: '/:vert/uninstall',
        component: Uninstall,
        beforeEnter: (to, from, next) => {
            function isValid() {
                const supportedVerticals = ['pdf', 'music', 'movie', 'private', 'incognito'];
                return supportedVerticals.includes(to.params.vert);
            }

            if (!isValid()) {
                next({name: '404'});
            }

            next();
        }
    },
    {
        path: '/:vert/:gdpr',
        component: GDPR,
        beforeEnter: (to, from, next) => {
            function isGDPR() {
                return ['help', 'privacy', 'contact', 'terms'].includes(to.params.gdpr);
            }

            if (!isGDPR()) {
                next({name: '404'});
            }

            next();
        }
    }

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
