<template>
    <div class="menu-list-wrapper">
        <ul class="menu-list">
            <menu-list-item v-for="(tab, index) in menuList"
                            :title="tab.title"
                            :description="tab.description" :icon="tab.icon" :key="tab.title"
                            :order="index + 1"/>
        </ul>
    </div>
</template>

<script>
    import menuList from './menu-list';
    import menuListItem from './menu-list-item';

    export default {
        name: "tabs-list",
        components: {
            menuListItem
        },
        data() {
            return {
                menuList
            }
        }
    }
</script>

<style scoped>
    .menu-list-wrapper {
        flex: 1;
        max-width: 400px;
    }

    .menu-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    @media (max-width: 1150px) {
        .menu-list-wrapper {
            margin-top: 50px;
        }
    }
</style>
