<template>
  <div class="settings-wrapper">
    <tab-title title="Edit Settings" v-if="this.isInstalled"></tab-title>
    <div class="settings" v-if="this.isInstalled || $route.query.yogev">
      <switch-button title="AI Suggestions" @onChange="toggleAutoComplete"
                     :toggle="hasAutoComplete" tooltip="Enable AI search suggestions"></switch-button>

      <drop-down v-if="matchingApp.length" title="AI Matching App" :options="matchingApp"
                 @onSelect="changeMatchingApp" cookie="rw"
                 tooltip="Default app for AI search results">
      </drop-down>

      <drop-down title="Search Engine" :options="searchEngines"
                 @onSelect="changeSearchEngine" cookie="se"
                 tooltip="Select your preferred search engine">
      </drop-down>

      <range-slider v-if="ext.vert === 'safe' ||ext.vert === 'safe'" :levels="safetyLevels"></range-slider>
    </div>

    <install v-if="$route.query.yogev !== '1'"></install>
  </div>
</template>

<script>
import DropDown from './dropDown';
import Install from './install';
import SearchEngines from '../helpers/searchEngines.js';
import MatchingApp from '../helpers/matchingApps.js';
import SwitchButton from './switchButton';
import TabTitle from './tabs/tab-title';
import RangeSlider from "./rangeSlider";
import {mapGetters} from 'vuex';

export default {
  name: 'settings',
  components: {
    RangeSlider,
    TabTitle,
    SwitchButton,
    Install,
    DropDown
  },
  computed: {
    ...mapGetters(['isInstalled', 'ext'])
  },
  mounted() {
    this.checkDefaultSearchEngine();
    this.checkDefaultMatchingApp();
  },
  data() {
    return {
      searchEngines: SearchEngines,
      matchingApp: MatchingApp[this.$store.getters.ext.vert],
      safetyLevels: ['Low', 'Moderate', 'Strict'],
      hasAutoComplete: this.$cookies.isKey('use_ac') ? this.$cookies.get('use_ac') === 'true' : true
    }
  },
  methods: {
    checkDefaultSearchEngine() {
      if (!this.$cookies.isKey('se')) {
        this.changeSearchEngine({name: "Default", value: "Yahoo"}, false);
      }
    },
    checkDefaultMatchingApp() {
      const vertical = this.$store.getters.ext.vert;
      if (!this.$cookies.isKey('rw') && this.matchingApp.length) {
        this.changeMatchingApp(MatchingApp[vertical][0], false);
      }
    },
    changeSearchEngine(selected, showToast = true) {
      this.$store.dispatch('changeSearchEngineAction', {
        selected,
        showToast: showToast
      });
    },
    changeMatchingApp(selected, showToast = true) {
      this.$store.dispatch('changeMatchingAppAction', {
        selected,
        showToast: showToast
      });
    },
    toggleAutoComplete(state) {
      this.hasAutoComplete = state;
      this.$store.dispatch('toggleAutoCompleteAction', {
        hasAutoComplete: this.hasAutoComplete
      });
    }
  }

}
</script>

<style scoped>
.settings-wrapper {
  width: 100%;
  height: 100%;
  user-select: none;
  text-align: center;
  padding: 8px;
  margin: auto;
}

.settings-wrapper .settings {
  width: max-content;
  margin: 50px auto;
  height: 300px;
  max-width: 370px;
}
</style>
