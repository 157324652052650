const {detect} = require('detect-browser');
const browser = detect();

export function getBrowser() {
    if (browser.name.includes('edge')) {
        return 'Edge';
    } else if (browser.name.includes('firefox')) {
        return 'Firefox';
    } else {
        return 'Chrome';
    }
}

export function getRootDomain() {
    const temp = location.host.split('.').reverse();
    return `${temp[1]}.${temp[0]}`;
}

export function capitalizeFirstLetter(string) {
    if (typeof string !== 'string' || !string.length) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1)
}




