<template>
  <div class="music">
    <div class="wrapper">
      <section class="content">
        <div class="container">
          <div class="block the_content">
            <h1>PRIVACY POLICY</h1>
            <h3>Last updated: August 12, 2020</h3>


            <h3>1. About Us</h3>
            <p>This extension is owned and operated by AppGenie team. We respect the privacy of users of our extension
              products and have developed this privacy policy (the "Privacy Policy") to demonstrate our commitment to
              protecting your privacy.</p>

            <h3>2. Scope</h3>
            <p>This Privacy Policy applies to information collected through Music Search for ChromeTM (“we”, “us” or
              “our”), whether accessed via computer, mobile device or other technology (collectively, the
              “Service”). </p>
            <p>This Privacy Policy explains what information we may collect through the Service, how such information
              may be used and/or shared with others, how we safeguard it and your choices about such uses and
              disclosures. </p>
            <p>Please read this Privacy Policy carefully, prior to using the Service. By using the Service, you are
              acknowledging that you understand and agree to the terms hereof as well as the <a
                  @click="goToPage('terms')">EULA
                LINK</a> set forth on
              the Site</p>


            <h3>3. What Information We Collect</h3>
            <p>Information collected directly from your usage and interaction of our Service, is as follows:</p>

            <table>
              <tr>
                <th>Category</th>
                <th>Examples</th>
              </tr>
              <tr>
                <td>Online Identifiers</td>
                <td>User Agent, IP address, User ID, Operating System</td>
              </tr>
              <tr>
                <td>Usage Information</td>
                <td>Page served, time, referring URLs</td>
              </tr>
              <tr>
                <td>Geolocation</td>
                <td>Geographic location associated with your IP address</td>
              </tr>
              <tr>
                <td>Others</td>
                <td>Search queries</td>
              </tr>
            </table>


            <h3>4. How We Collect Your Information</h3>
            <p>The data detailed in the table above is collected Automatically, mainly through the use of cookies.</p>

            <span><strong>Cookies</strong></span>
            <p>Cookies are small text files placed on a Device when the Device is used to visit the Service or the Site.
              The extension collects your information with cookies on the search domain level. </p>

            <span><strong>Managing Cookies/Opt-Out</strong></span>
            <p>Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary
              from browser to browser, and from version to version. You can however obtain up-to-date information about
              blocking and deleting cookies via this link: <a class="hyper"
                                                              href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a>
            </p>
            <p>In addition, we may offer Oath search feed as part of our Extension and thus it may include cookies on
              its behalf - more information is available here:
              <a class="hyper" href="https://policies.oath.com/us/en/oath/privacy/products/searchservices/index.html">https://policies.oath.com/us/en/oath/privacy/products/searchservices/index.html</a>.
              In addition, opt out
              information which will enable you to limit the data collection by OATH is available here:
              <a class="hyper"
                 href="https://aim.yahoo.com/aim/us/en/optout/">https://aim.yahoo.com/aim/us/en/optout/</a></p>
            <p>Please note that blocking all cookies will have a negative impact upon the usability of many websites. If
              you block cookies, you might not be able to use all the features of our extension.</p>


            <h3>5. How We Use Your Information </h3>
            <p>We use information that we collect about you for:</p>
            <span>a. Operating the Extension</span>
            <span>b. Providing customized Service</span>
            <span>c. Internal operation of debugging, support, fraud detection and security</span>
            <span>d. Monetization with third party search partners</span>


            <h3>6. How We Share Your Information</h3>
            <p>We do not disclose information collected from you with any third party, other than with the service
              providers we use solely for the purposes detailed in Section 5. Below is a detailed list of our
              third-party service providers that we share information with:</p>

            <span>a. Cloudflare (name server, DNS, caching)</span>
            <span>b. Google Cloud (web hosting)</span>
            <span>c. Yahoo search feed (third party search monetization)</span>
            <span>d. Zoho Form (administrative)</span>
            <span>e. Trello (administrative)</span>

            <p>We also reserve the right to disclose your information with third parties if we are legally required to
              do so, to cooperate with law enforcement investigations or other legal proceedings, or to protect against
              misuse or unauthorized use of the Service, solely to the extent needed to enforce our policies and
              agreements and to investigate potential violations thereof.</p>

            <h3>7. How We Protect Your Information</h3>
            <p>We maintain tight controls over all the data we collect, retaining it in secured databases with limited
              and controlled access rights. Please remember that unfortunately, the transmission of information via the
              internet is not completely secure, so although we will do our best to protect your Personal Data, we
              cannot guarantee the security of your data transmitted via the Service; any transmission is at your own
              risk. </p>


            <h3>8. How Long We Keep Your Information</h3>
            <p>We only keep your personal information as long as it is necessary for the purposes set out in this
              Privacy Policy including for legitimate business purposes and as permitted by applicable law. We will only
              use your information to the extent necessary to comply with our legal obligations, resolve any legal
              issues, enforce our agreements, and as otherwise described in this policy.</p>
            <p>Please note, that once the Extension has been removed from your browser by you, we can no longer trace
              any user activity nor can we link you with any unidentifiable information we may have in our database.</p>


            <h3>9. Notice to EU Residents under the “GDPR” </h3>
            <p>This section applies to you if you are a national of a European Economic Area (“EEA”) member state. This
              section describes our good faith effort to meet our obligations under the GDPR.</p>

            <span><strong>Personal Data </strong></span>
            <p>The GDPR, defines “personal data” as “…any information relating to an identified or identifiable natural
              person (‘data subject’); an identifiable natural person is one who can be identified, directly or
              indirectly, in particular by reference to an identifier such as a name, an identification number, location
              data, an online identifier or to one or more factors specific to the physical, physiological, genetic,
              mental, economic, cultural or social identity of that natural person”</p>
            <p>For the purposes of our Privacy Policy, the “data subject” is “you” (“your”, “user”). The personal data
              we collect may include: IP Address and the unique User ID we assign you.</p>
            <p>The personal data we collect will only be used and disclosed for the purposes detailed in sections 5 and
              6 to this Privacy Policy.</p>
            <p>Our basis for processing personal data: GDPR Recital 47: “The processing of personal data strictly
              necessary for the purposes of preventing fraud also constitutes a legitimate interest of the data
              controller concerned.”; GDPR Article 6(1)(f): “processing is necessary for the purposes of the legitimate
              interests pursued by the controller or by a third party…”</p>

            <span><strong>Your Rights</strong></span>
            <p>Please contact us by submitting <a class="hyper" :href="Feedback[$store.getters.ext.vert]">the
              following form</a> , in case you wish to exercise your rights as a GDPR
              data subject, including:</p>
            <span>a. Request a copy of information, if any, stored about you.</span>
            <span>b. Correct information, if any, stored about you.</span>
            <span>c. Erase information, if any, stored about you.</span>
            <span>d. Restrict how information, if any, about you is used.</span>
            <span>e. Transfer the information, if any, about you.</span>
            <span>f. Object to how your information, if any, is used in certain cases.</span>
            <span>g. Request why we are unable to fulfill your request, if applicable.</span>


            <h3>10. Notice to California Residents under the “CCPA” </h3>
            <p>This Section applies to you if you are a natural person who is a California resident, as defined in the
              California Code of Regulations. This section describes our good faith effort to meet our obligations under
              the CCPA.</p>

            <span><strong>Personal Information</strong></span>
            <p>The CCPA, defines “personal information” as including “…information that identifies, relates to,
              describes, is capable of being associated with, or could reasonably be linked, directly or indirectly,
              with a particular consumer or household.”</p>
            <p>For the purposes of our Privacy Policy, the “person” is “you” (“your”, “user”). The personal information
              we may collect includes: IP Address; the unique User ID we assign you; the User Agent and version of your
              web browser; and the Operating System and version used by your hardware.</p>
            <p>The personal information we collect will only be used and disclosed for the business purposes detailed in
              sections 5 and 6 to this Privacy Policy, which are necessary for us in order to provide you with the
              Service.</p>

            <span><strong>Your Rights / Opt-Out for “Sale of Personal Information”</strong></span>
            <p>You can contact us to submit a CCPA inquiry via the following 2 methods:</p>
            <span>●	<a class="hyper" :href="Feedback[$store.getters.ext.vert]">Filling in the form</a> </span>
            <span>●	Submitting an inquiry to admin@amazingosapps.com</span>
            <span>●	Sending a physical inquiry to 4 World Trade Center, Suite 2983, New York, New York 10007, USA</span>

            <p>Please note, that we do not explicitly sell your personal information, therefore, there is no need for
              you to opt-out of the potential sale of your personal information. </p>


            <h3>11. Children</h3>
            The Service is not directed to children, as defined under applicable laws and specifically under 16 in the
            EEA or 13 in the U.S.A. (“Child”). We do not knowingly collect Personal Information from anyone that is
            considered to be a child. If we determine upon collection that a user is a child, we will not use or
            maintain his/her Personal Information without the parent/guardian’s consent. If we become aware that we have
            unknowingly collected Personal Information from a child under the age of 13, we will make reasonable efforts
            to delete such information from our records. We encourage parents and legal guardians to be familiar with
            the Internet activity of their children.

            <h3>12. How to remove extension</h3>
            <p>As a user of our extension, you can remove the extension from your browser at any time. For simple steps
              to remove our extension, please click <a class="hyper" @click="goToPage('help')">HERE</a></p>

            <h3>13. Updates to Privacy Policy</h3>
            <p>Please note, we may modify information presented via the extension and/or this Privacy Policy from time
              to time. You should check back here periodically to see if the Privacy Policy has been updated. We will
              always show the date of the latest modification of the Privacy Policy at the top of the page so you can
              tell when it was last revised.</p>

            <h3>14. Contact us</h3>
            <p>We regularly review our compliance with this Privacy Policy. Questions, comments and requests regarding
              this Privacy Policy are welcomed and should be addressed to admin@amazingosapps.com. You may also
              submit
              inquiries to our appointed Data Protection Officer by the above email address or deliver a physical
              inquiry to 4 World Trade Center, Suite 2983, New York, New York 10007, United States.</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Feedback from '@/helpers/feedback';

export default {
  name: "music",
  data() {
    return {
      Feedback
    }
  },
  methods: {
    goToPage(page) {
      const vert = this.$store.getters.ext.vert;
      this.$router.push(`/${vert}/${page}`);
    }
  }
}
</script>

<style scoped>

.wrapper {
  animation-duration: 1s;
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

svg {
  display: block;
}

a, a:hover {
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h3 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

img {
  max-width: 100%;
  height: auto;
}


a:hover {
  color: #36caf5;
}


.container {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
}

.block {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  padding: 40px;
}

span {
  display: block;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.hyper {
  text-decoration: underline;
  color: purple;
}
</style>