<template>
  <div class="wrapper">
    <section class="content">
      <div class="container">
        <div class="block the_content">
          <h1>EULA / Terms of Use</h1>
          <h3>Updated on August 12, 2020</h3>

          <p>PLEASE READ THIS TERMS AND LICENSE CAREFULLY BEFORE INSTALLING OR USING OUR SITE/EXTENSION/PRODUCT. BY
            INSTALLING OUR EXTENSION/PRODUCT OR USING OUR SITE, YOU AGREE TO BE BOUND BY ALL TERMS AND CONDITIONS OF
            THIS AGREEMENT AND ALL POLICIES AND GUIDELINES INCORPORATED BY REFERENCE.</p>
          <p>PLEASE READ THIS AGREEMENT CAREFULLY. IF YOU DO NOT WISH TO BE BOUND BY THIS AGREEMENT, DO NOT INDICATE
            YOUR ACCEPTANCE, AND DO NOT USE OUR SITE OR INSTALL THE EXTENSION/PRODUCT.</p>
          <p>For more details about how we collect, store, share and use information collected, please visit our privacy
            policy (the " Privacy Policy ").</p>

          <p>PLEASE READ THIS AGREEMENT CAREFULLY. IF YOU DO NOT WISH TO BE BOUND
            BY THIS AGREEMENT, DO NOT INDICATE YOUR ACCEPTANCE, AND DO NOT USE
            OUR SITE OR INSTALL THE EXTENSION/PRODUCT.
            For more details about how we collect, store, share, and use information collected, please visit our
            privacy policy (the "&nbsp;Privacy Policy&nbsp;").</p>
          &nbsp;
          <h3>Acceptance of Terms</h3>
          <p>Following your acceptance of this EULA, by installing our Extension/Product, the Extension/Product will
            change your Chrome browser’s default search engine to a sponsored search engine. If you do wish to change
            your default search engine or in case you wish to reset such change, you can do so by either - (i) resetting
            your default search engine via browser settings; (ii) temporarily disabling the Extension/Product;
            un-installing the Extension/Product. The Extension/Product may include Yahoo’s search feed (powered by
            Microsoft Bing), which may further link to third-party sites and/or content (collectively, “Third-Party
            Content”). Any use by you of Third-Party Content that is available via the Extension/Product is subject to
            the terms and conditions of any such Third-Party Content. You can read Yahoo’s international Terms of
            Service here: <a class="hyper" href="https://info.yahoo.com/legal/us/yahoo/utos/utos-173.html">https://info.yahoo.com/legal/us/yahoo/utos/utos-173.html</a>. You acknowledge that we have no
            control over and assume no responsibility for Third Party Content. You assume all responsibility and risk in
            connection with your use of Third-Party Content and we hereby disclaim any and all liability to you or any
            third party in connection thereto. We have no obligation to examine, review or monitor Third Party Content
            and are not responsible for the accuracy, completeness, appropriateness or legality of Third-Party Content.
            You acknowledge and agree that certain Third-Party Content may cease to be available to you from the
            Software at our sole discretion and without notification to you.</p>
          <p>This EULA and the privacy policy (together, this " Agreement ") govern your use of our product. You may not
            use our products if (a) you are not of legal age to form a binding contract with us, or (b) you are a person
            barred from receiving or using the product under the laws of the United States or other countries, including
            the country in which you are resident or from which you use the product.</p>
          <p>This Agreement is a legally binding agreement.</p>
          <p>By using and/or visiting Music Search for ChromeTM you acknowledge that we will not be liable for any
            damages, claims or other liability arising from and / or related use of Web sites owned by third
            parties.</p>

          <h3>Age Requirement</h3>
          <p>You must be 13 years of age or older to install or to use the Site/Extension/Product.</p>


          <h3>License & Use Restrictions</h3>
          <p>Subject to your compliance with these Terms, we hereby grant you a limited, personal, non-transferable,
            non-sublicensable, license (NOT SOLD) to use Music Search for ChromeTM on applicable devices that are under
            your control, provided that you will fully comply with the terms and conditions of these Terms and our
            Privacy Policy. You may not do any of the following: (1) Interfere with the security of the product in some
            way. (2) Use the product in a way that violates or disrupts the services it provides. (3) Make any use of a
            product that violates the legal rights of any user. (4) Use the product illegally. (5) Use the product in
            violation of applicable law or regulation.</p>
          <p>We reserve the right at our sole discretion to suspend, remove, or disable your access to Music Search for
            ChromeTM at any time and without notice. In no event we will be liable for the suspension, removal of or
            disabling of your access or to any feature available therein.</p>

          <h3>Un-install</h3>
          <p>You can easily uninstall Music Search for ChromeTM by following the instructions at our <a
              @click="goToUninstallPage">Uninstall page</a></p>

          <h3>Linked Content</h3>
          <p>Some of the content displayed or linked to by us is developed by people over whom we exercise no control
            and we cannot and do not screen the sites before including them in the Site and/or Product. We cannot
            guarantee that any content will not contain unintended or objectionable content and assume no responsibility
            for the content of any site included in the Site and/or Product, and we do not assume responsibility for the
            goods, services, or information provided by such sites.</p>

          <h3>Personal Use Only</h3>
          <p>Our Services are made available for your personal, non-commercial use only. You may not take the Site ’s
            and/or Product ’s different content and display them, or mirror any home page or results pages on your
            website or other product.</p>

          <h3>“In-app ”Purchases / Paid Service(s)</h3>
          <p>Our Services may include specific services that can be licensed for a fee , typically via an “in-app
            purchase ”or “subscription ”(“ Purchased Content ”). You may only license Purchased Content if you are over
            18 years old, and only on a limited, personal, non-transferable, non-sublicensable and revocable basis for
            non-commercial use only.</p>

          <h3>“In-app ”Purchases / Paid Service(s)</h3>
          <p>Our Services may include specific services that can be licensed for a fee , typically via an “in-app
            purchase ”or “subscription ”(“ Purchased Content ”). You may only license Purchased Content if you are over
            18 years old, and only on a limited, personal, non-transferable, non-sublicensable and revocable basis for
            non-commercial use only.</p>
          <p>Please note that if you remove our Extension/Product or deactivate your account, you may lose future access
            to previously Purchased Content even if you later re-download the Extension/Product or re-activate your
            account.</p>
          <p>In addition, any “ subscription/s ”previously purchased (on a monthly/yearly subscription basis) may not be
            automatically canceled in case you remove our Extension/Product or deactivate your account and will
            therefore stay in effect until you “ un-subscribe ”.</p>
          <p>We reserve the right to manage, regulate, control, modify or eliminate Purchase Content at any time.</p>
          <p>Upon the Purchased Content being made available to you, our obligations to provide Purchase Content are
            deemed fulfilled, and we make no representations that Purchased Content will be available to the purchaser
            for any length of time nor that the Services will continue to support Purchased Content.</p>
          <p>SUBJECT TO MANDATORY LEGISLATION, YOU ACKNOWLEDGE THAT WE WILL NOT BE REQUIRED TO PROVIDE A REFUND FOR
            PURCHASED CONTENT FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED
            PURCHASED CONTENT, WHETHER YOUR LOSS OF LICENSE WAS VOLUNTARY OR IN VOLUNTARY.</p>

          <h3>Termination</h3>
          <p>We may at any time, with or without notice, terminate the Agreement or block or disable your access or use
            of our Product or Service if:</p>
          <p>We believe that you have breached any provision of the Agreement (or have acted in manner which shows that
            you do not intend to, or are unable to comply with the provisions of the Agreement).</p>
          <p>We are required to do so by law (for example, where the provision of the Product or Service to you is, or
            becomes, unlawful).</p>
          <p>The provision of the Product or Service to you by us is, in our opinion, no longer commercially viable.</p>
          <p>We believe that your use of the Product or Service may infringe or violate the rights of a third party or
            subject Licensor to civil or criminal liability.</p>
          <p>All of the provisions of this Agreement which, by their nature, are intended to survive termination hereof
            (including, without limitation, all provisions relating to indemnification, disclaimer of warranties,
            indemnification, intellectual property rights, limitation of liability, applicable law, jurisdiction and
            venue, class actions and general matters), shall do so.</p>

          <h3>Copyright</h3>
          <p>“Intellectual Property ”–including any and all trade secrets, patents, copyrights, trademarks, service
            marks, URLs, trade dress, brand features, know-how, moral rights, contract rights, code (executable, source
            and other) and similar rights of any type under the laws of any applicable governmental authority, or
            international treaty, including, without limitation, all applications and registrations relating to any of
            the foregoing. Intellectual Property shall mean all intellectual property whether registered or not and
            whether reduced into practice or not.</p>
          <p>All content on the product, including text documents graphics software, etc., as well as trademarks, logos,
            are solely owned by us or licensed to us. We have the right to edit, delete, distort, modify or move user
            content without notice for any reason at any time. Additionally, we have the right to refuse to transmit,
            email, post or broadcast any content without notice for any reason at any time.</p>
          <h3>Indemnification</h3>
          <p>You agree to indemnify and to hold us ("Indemnifying Party ") harmless, and indemnify us from and against
            any expenses (including attorney fees) arising from claims of third-parties made against or incurred by the
            Indemnified Party as a result of negligence, misrepresentation, error or omission on the part of the
            Indemnifying Party or any employee, agent or representative of the Indemnifying Party, or any breach of this
            Agreement by the Indemnifying Party, regardless of whether such claims were foreseeable by the Indemnified
            Party. The Indemnified Party will have the right to participate, at its expense, in the defense of any claim
            covered hereunder with counsel of its own choosing. The Indemnified Party shall give the Indemnifying Party
            prompt notice of any such claim and shall reasonably cooperate with the Indemnifying Party and its counsel
            in the defense of such claim.</p>

          <h3>WARRANTIES</h3>
          <p>WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND EXCEPT AS EXPLICITLY SET FORTH IN THIS AGREEMENT,
            ALL CONTENT ON THE SITE IS PROVIDED "AS-IS ". WE DO NOT WARRANT THAT THE LICENSED PRODUCT IS ERROR-FREE OR
            FREE FROM INTERRUPTIONS OR OTHER FAILURES OR THAT THE PRODUCT WILL MEET DISTRIBUTOR 'S OR THE END CUSTOMER
            'S REQUIREMENTS.</p>
          <p>IN NO EVENT WILL LICENSOR, OR ANY OF ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE OFFICERS, DIRECTORS,
            EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS, MEMBERS, ADVERTISERS, INFORMATION PROVIDERS OR LICENSORS BE
            LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR OTHER DAMAGES (REGARDLESS
            OF THE FORM OF ACTION), OR FOR ANY LOSS OF PROFITS, REVENUES, BUSINESS OPPORTUNITIES, DATA OR USE OF
            SYSTEMS, ARISING OUT OF (i) USE OF THE PRODUCT BY ANY PERSON, INCLUDING BUT NOT LIMITED TO, ANY DAMAGE
            CAUSED BY ANY RELIANCE ON, OR ANY DELAYS, INACCURACIES, DEFECTS, MALFUNCTIONS, ERRORS OR OMISSIONS IN, ANY
            INFORMATION, CONTENT OR SOFTWARE ACCESSED VIA THE PRODUCT, OR (ii) ANY USE OR INABILITY TO USE THE PRODUCT
            FOR WHATEVER REASON, INCLUDING BUT NOT LIMITED TO COMMUNICATIONS FAILURE OR ANY OTHER FAILURE WITH
            TRANSMISSION OR DELIVERY OF ANY INFORMATION ACCESSED THROUGH THE PRODUCT. SOME JURISDICTIONS DO NOT ALLOW
            THESE LIMITATIONS OR EXCLUSIONS SO THEY MAY NOT APPLY TO YOU.</p>

          <h3>Limitation of Liability</h3>
          <p>YOU UNDERSTAND AND AGREE THAT THE WEBSITE, PRODUCT AND EXTENSION ARE PROVIDED ON AN "AS IS "AND "AS
            AVAILABLE "BASIS. YOU EXPRESSLY AGREE THAT USE OF THE WEBSITE, PRODUCT AND EXTENSION IS AT YOUR SOLE RISK.
            TO THE FULLEST EXTENT PERMISSIBLE UNDER THE APPLICABLE LAW, LICENSOR AND ITS AFFILIATES DISCLAIM ALL
            WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, WARRANTIES OF TITLE AND IMPLIED
            WARRANTIES OF MERCHANTIBILITY OR FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, NEITHER
            LICENSOR NOR ANY OF ITS AFFILIATES, NOR ANY OF THEIR OFFICERS, DIRECTORS, LICENSORS, EMPLOYEES OR
            REPRESENTATIVES REPRESENT OR WARRANT (i) THAT THE WEBSITE, PRODUCT AND EXTENSION, INCLUDING THEIR CONTENT,
            WILL MEET YOUR REQUIREMENTS OR BE ACCURATE, COMPLETE, RELIABLE, OR ERROR-FREE; (ii) THAT THE WEBSITE,
            PRODUCT AND EXTENSION WILL ALWAYS BE AVAILABLE OR WILL BE UNINTERRUPTED, ACCESSIBLE, TIMELY, OR SECURE;
            (iii) THAT ANY DEFECTS WILL BE CORRECTED, OR THAT THE WEBSITE, PRODUCT AND EXTENSION WILL BE FREE FROM
            VIRUSES, "WORMS,""TROJAN HORSES "OR OTHER HARMFUL PROPERTIES; (iv) THE ACCURACY, RELIABILITY, TIMELINESS, OR
            COMPLETENESS OF ANY REVIEW, RECOMMENDATION, OR OTHER MATERIAL PUBLISHED OR ACCESSIBLE ON OR THROUGH THE
            PRODUCT; (v) ANY IMPLIED WARRANTY ARISING FROM COURSE OF DEALING OR USAGE OF TRADE; AND (vi) THAT THE
            WEBSITE, PRODUCT AND EXTENSION IS NONINFRINGING. LICENSOR AND ITS AFFILIATES HEREBY DISCLAIM, AND YOU HEREBY
            WAIVE AND RELEASE LICENSOR AND ITS AFFILIATES FROM, ANY AND ALL OBLIGATIONS, LIABILITIES, RIGHTS, CLAIMS, OR
            REMEDIES IN TORT ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR THE WEBSITE, PRODUCT AND EXTENSION,
            WHETHER OR NOT ARISING FROM THE NEGLIGENCE (ACTIVE, PASSIVE OR IMPUTED) OF LICENSOR OR ANY OF ITS
            AFFILIATES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY
            NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER LEGAL RIGHTS, WHICH VARY FROM JURISDICTION TO JURISDICTION.</p>

          <h3>Miscellaneous</h3>
          <p>THIS AGREEMENT SHALL BE CONSTRUED AND ENFORCED IN ACCORDANCE WITH THE LAWS OF ISRAEL, WITHOUT REFERENCE TO
            ITS CONFLICTS OF LAW PRINCIPLES. The courts located in Tel-Aviv Israel shall have exclusive jurisdiction to
            adjudicate any dispute arising out of this Agreement and each party hereby expressly consents to the
            personal jurisdiction of such courts. A determination that any term of this Agreement is invalid or
            unenforceable shall not affect the other terms thereof. Section headings are for convenience of reference
            only and shall not affect the interpretation of this Agreement.</p>
          <p>Nothing in these Agreement creates any agency, employment, joint venture, or partnership relationship
            between you and us. Except as may be expressly stated in these Terms, these Terms constitute the entire
            agreement between us and you pertaining to the subject matter hereof. We may assign our rights and
            obligations under these Terms and the Privacy Policy. You may not assign your rights and obligations
            hereunder without our prior written consent.</p>
          <p>YOU AGREE THAT YOURE CONTINUED USE OF THE WEBSITE, PRODUCT AND EXTENSION, FOLLOWING THE POSTING OF ANY
            CHANGES TO THIS AGREEMENT AND AFTER THE CHANGES TAKE EFFECT WILL CONSTITUTE YOUR ACCEPTANCE OF SUCH CHANGES.
            IF YOU DO NOT AGREE TO FUTURE CHANGES TO THIS AGREEMENT, STOP USING THE SITE AND OUR PRODUCTS AFTER THE
            EFFECTIVE DATE OF SUCH CHANGES AND UNINSTALL THE PRODUCT.</p>
          <p>We are constantly innovating in order to provide the best possible experience for our users. You
            acknowledge and agree that the form, features or nature of the product we provide may change from time to
            time without prior notice to you. We may also cease or discontinue providing the product or upgrades at any
            time.</p>
          <p>For details about our data collection and data use practices, please see our Privacy Policy. As explained
            in the Privacy Policy, we may collect and store information from your use or web sessions which may be used
            to display targeted advertising.</p>


          <h3>Contact Us</h3>
          <p>If you have any questions, complaints and/or claims, you may contact us at:  admin@amazingosapps.com</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "music",
  methods: {
    goToUninstallPage() {
      window.open(this.$store.getters.ext.dislike);
    }
  }
}
</script>

<style scoped>

.wrapper {
  animation-duration: 1s;
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

svg {
  display: block;
}

a, a:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h3 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

img {
  max-width: 100%;
  height: auto;
}


a:hover {
  color: #36caf5;
}

.container {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
}

.block {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  padding: 40px;
}

.hyper {
  text-decoration: underline;
  color: purple;
}

</style>