<template>
    <div class="toggle-button">
        <span>{{title}}</span>
        <toggle-button :value="toggle"
                       :sync="true"
                       :labels="{checked: 'ON', unchecked: 'OFF'}"
                       :width="80" :height="35"
                       :font-size="15"
                       @change="onChange">
        </toggle-button>
        <tooltip :tip="tooltip"></tooltip>
    </div>

</template>

<script>
    import Tooltip from './tooltip';

    export default {
        name: "switchButton",
        components: {Tooltip},
        props: ['title', 'toggle', 'tooltip'],
        methods: {
            onChange(toggle) {
                this.$emit('onChange', toggle.value);
            }
        }
    }
</script>

<style scoped>

    .toggle-button {
        display: flex;
        width: 370px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        justify-content: space-between;
        padding: 6px;
        align-items: center;
    }


    .toggle-button span {
        font-weight: 500;
        align-self: center;
        padding: 6px;
        width: 60%;
        text-align: left;
    }

</style>