<template>
  <div class="top-bar" :style="{'background': setBackgroundImage()}">
    <div class="brand">
      <img :src="setGenieIcon()" alt="" @click="refreshPage">
      <transition name="fade" appear>
        <span v-if="$store.getters.ext.extensionName">{{ $store.getters.ext.extensionName }}</span>
      </transition>
    </div>

    <top-bar-options></top-bar-options>
  </div>
</template>

<script>
import TopBarOptions from './top-bar-options';

export default {
  name: 'top-bar',
  components: {TopBarOptions},
  methods: {
    setGenieIcon() {
      try {
        return require(`../../assets/img/genie/${this.$store.getters.ext.vert}.svg`);
      } catch (e) {
        return require('../../assets/img/genie/genie.svg')
      }
    },
    setBackgroundImage() {
      try {
        return '#ffffff url(' + require(`../../assets/img/top-bar/${this.$store.getters.ext.vert}.svg`) + ') no-repeat';
      } catch (e) {
        return '#fff';
      }
    },
    refreshPage() {
      if (this.$route.path !== `/${this.$store.getters.ext.vert}`) {
        this.$router.push(`/${this.$store.getters.ext.vert}`);
      } else {
        this.$store.dispatch('setActiveTab', 1);
      }
    }
  }
}
</script>

<style scoped>

.top-bar {
  height: 50px;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-shadow: 0 1px 4px 1px lightgrey;
  box-shadow: 0 1px 4px 1px lightgrey;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  background-size: cover !important;
  z-index: 10;
}


.top-bar .brand {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  color: rgba(142, 137, 137, 0.4);
}


.top-bar .brand img {
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 100px;
  -webkit-transform: translateZ(0);
  cursor: pointer;
}


.top-bar .brand img:hover {
  background-color: #b2def9;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.top-bar .brand img:hover:after {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition: -webkit-transform 0.2s;
  -moz-transition: -moz-transform 0.2s;
  -o-transition: -o-transform 0.2s;
  transition: transform 0.2s;
}

.top-bar .brand img:after {
  position: absolute;
  top: -10px;
  left: -10px;
  content: '';
  height: 100%;
  width: 100%;
  padding: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.8);
  -webkit-transform: scale(0.7, 0.7);
  -moz-transform: scale(0.7, 0.7);
  -ms-transform: scale(0.7, 0.7);
  -o-transform: scale(0.7, 0.7);
  transform: scale(0.7, 0.7);
  -webkit-transition: -webkit-transform 0.2s;
  -moz-transition: -moz-transform 0.2s;
  -o-transition: -o-transform 0.2s;
  transition: transform 0.2s;
}


.top-bar .brand span {
  color: black;
  font-weight: bold;
  margin-left: 5px;
  width: max-content;
  font-family: Montserrat, sans-serif;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>