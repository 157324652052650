<template>
  <li :class="{'menu-link-item': true, 'active': $store.getters.activeTab === order, [icon]: true}"
      @click="setActiveTab(order)">
    <div class="icon-wrapper">
      <img :src="setTabIcon(icon)" alt="">
    </div>
    <div class="content-wrapper">
      <span class="title">{{ title }}</span>
      <span class="description">{{ description }}</span>
    </div>
  </li>
</template>

<script>
export default {
  name: "tabs-list-item",
  props: ['title', 'description', 'icon', 'order'],
  methods: {
    setActiveTab(tab) {
      this.$store.dispatch('setActiveTab', tab);
    },
    setTabIcon(icon) {
      try {
        return require(`../../assets/img/tab-icons/${icon}.svg`);
      } catch (e) {
        console.log('tab image not found');
      }
    }
  }
}
</script>

<style scoped>
li {
  user-select: none;
}

.title {
  display: block;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.description {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  line-height: 24px;
  display: block;
}

.icon-wrapper {
  width: 50px;
  margin-right: 16px;
  font-size: 48px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.content-wrapper {
  display: inline-block;
  vertical-align: middle;
}

.menu-link-item {
  text-align: left;
  padding: 8px 16px;
  cursor: pointer;
  width: 400px;
  transition: all .3s;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 85px;
  animation-duration: 1.5s;
  animation-name: fadeInUp;
}


.menu-link-item:nth-child(1) {
  animation-delay: .05s;
}

.menu-link-item:nth-child(2) {
  animation-delay: .1s;
}

.menu-link-item:nth-child(3) {
  animation-delay: .15s;
}


.menu-link-item img {
  display: block;
}

.menu-link-item.question:hover, .menu-link-item.question.active {
  background-color: rgba(209, 237, 206, .5);
}

.menu-link-item.question .icon-wrapper {
  color: rgb(90, 208, 110);
}

.menu-link-item:hover, .menu-link-item.active {
  background-color: #9FB3D6;
}

.menu-link-item.cog:hover, .menu-link-item.cog.active {
  background-color: rgba(119, 72, 255, 0.2);
}

.menu-link-item.cog .icon-wrapper {
  color: #7747ff;
}

.menu-link-item.info:hover, .menu-link-item.info.active {
  background-color: rgba(255, 202, 94, 0.2);
}

.menu-link-item.info .icon-wrapper {
  color: #faca5e;
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

</style>
