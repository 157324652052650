<template>
  <div id="app" class="background" :style="{background: setBackgroundImage()}">
    <cookie-consent></cookie-consent>
    <top-bar v-if="this.$route.query.tab !== '3' && $store.getters.ext.vert !== '404'"></top-bar>
    <router-view></router-view>
  </div>
</template>

<script>
import CookieConsent from '@/components/cookieConsent';
import TopBar from "@/components/top-bar/top-bar";

export default {
  name: 'app',
  components: {
    TopBar,
    CookieConsent
  },
  beforeMount() {
    this.setVertical();
    this.setRootDomain();
    this.setDefaultTabSettings();
  },
  mounted() {
    this.setActiveTab();
  },
  methods: {
    setVertical() {
      const vert = this.$router.currentRoute.params.vert || this.$router.currentRoute.name;
      this.$store.dispatch('setVertAction', vert);
    },
    setRootDomain() {
      let domain = window.location.hostname;
      const parts = domain.split('.');
      if (parts.length >= 3) {
        parts.shift();
        domain = parts.join('.');
      }
      this.$store.dispatch('setRootDomain', domain);
    },
    setActiveTab() {
      const activeTab = Number.parseInt(this.$route.query.tab) || 1;
      this.$store.dispatch('setActiveTab', activeTab);
    },
    setDefaultTabSettings() {
      document.title = 'AppGenie';
      if (this.$route.query.tab === '3') {
        document.title = 'Settings';
        this.changeFavicon();
      }
    },
    changeFavicon() {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = '';
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    setBackgroundImage() {
      const vertical = this.$store.getters.ext.vert;
      try {
        const image = require(`./assets/img/background/${vertical}.svg`);
        return `url(${image}) no-repeat center center / cover`;
      } catch (e) {
        console.log('Background image was not found.');
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,700&display=swap');

#app {
  font-family: 'Poppins', sans-serif;
  overflow: auto;
}


.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

}

.toasted {
  font-family: 'Poppins', sans-serif;
}

.toasted-container .toasted {
  height: 70px !important;
  font-size: 18px !important;
  box-shadow: 0 4px 5px 0 grey !important;
}

.toasted.bubble.success {
  background: #2FC1EA !important;
}

.swal2-popup.swal2-toast .swal2-header {
  font-family: 'Poppins', sans-serif;
  user-select: none;
}

.swal2-popup.swal2-toast .swal2-title {
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
