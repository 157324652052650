<template>
  <div class="cookie-consent" v-show="showConsent === 'show'">
    <p>This website uses cookies to ensure you get the best experience.
      <b class="details" @click="showMoreDetails">More Details</b>
    </p>
    <rounded-button text="Okay" @buttonClick="dontShowAgain"></rounded-button>
  </div>
</template>

<script>
import RoundedButton from './roundedButton';
import GDPR from '@/helpers/gdpr';

export default {
  name: "cookieConsent",
  components: {RoundedButton},
  data() {
    return {
      get showConsent() {
        return localStorage.getItem('cookieConsent') || 'show';
      },
      // eslint-disable-next-line vue/no-dupe-keys
      set showConsent(value) {
        localStorage.setItem('cookieConsent', value);
      }
    }
  },
  methods: {
    dontShowAgain() {
      this.showConsent = 'hide';
    },
    showMoreDetails() {
      const url = GDPR[this.$store.getters.ext.vert].privacy;
      if (url) {
        return window.open(url);
      }

      const vert = this.$store.getters.ext.vert || 'music';
      this.$router.push(`/${vert}/privacy`);
    }
  }
}
</script>

<style scoped>

.cookie-consent {
  position: fixed;
  z-index: 9;
  right: 5px;
  bottom: 5px;
  width: max-content;
  background: rgba(47, 47, 47, 0.95);
  display: inline-flex;
  justify-content: space-between;
  padding: 10px;
  color: white;
  border-radius: 7px;
  align-items: center;
  box-shadow: 0 1px 9px 4px lightgrey;
}

.cookie-consent p {
  width: 525px;
  text-align: center;
  margin: 0;
}

.cookie-consent .details {
  cursor: pointer;
  color: lightskyblue;
}

</style>