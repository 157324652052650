<template>
    <div class="drop-down" v-click-outside="hideDropDown">
        <span>{{title}}</span>
        <div class="custom-select" @blur="open = false">
            <div class="selected" :class="{open: open}" @click="open = !open">
                {{ selected }}
            </div>
            <div class="items" :class="[open ? 'show' : 'hide']">
                <div class="dividing-line"></div>
                <div class="item" v-for="(option, i) of options" :key="i"
                     @click="onSelected(option)">
                    {{ option.name }}
                </div>
            </div>
        </div>
        <tooltip :tip="tooltip"></tooltip>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import Tooltip from './tooltip';
    import {capitalizeFirstLetter} from '../helpers/utils.js';

    export default {
        name: 'dropDown',
        components: {Tooltip},
        props: ['options', 'title', 'cookie', 'tooltip'],
        mounted() {
            this.setSelectedItem();
        },
        data() {
            return {
                selected: '',
                open: false
            };
        },
        methods: {
            hideDropDown() {
                this.open = false;
            },
            /**
             * Setting the current search engine from cookies, if it doesn't exist - use the default.
             */
            setSelectedItem() {
                if (this.cookie === 'rw') {
                    return this.selected = this.$cookies.get(this.cookie) || this.options[0].name;
                }

                this.selected = capitalizeFirstLetter(this.$cookies.get(this.cookie)) || this.options[0].name;
            },
            onSelected(option) {
                this.selected = option.name;
                this.open = false;

                const obj = JSON.parse(JSON.stringify(this.options[this.options.findIndex(obj => obj.name === this.selected)]));
                if (obj) {
                    this.$emit('onSelect', obj);
                }
            }
        },
        directives: {
            ClickOutside
        }
    };
</script>

<style scoped>

    .drop-down {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding: 6px;
        justify-content: space-between;
        text-align: left;
        width: 370px;
        align-items: center;
        transition: all 0.25s ease-in-out;
    }

    .drop-down span {
        font-weight: 500;
        align-self: center;
        padding: 6px;
        width: 37%;
    }

    .custom-select {
        position: relative;
        width: 165px;
        text-align: left;
        outline: none;
        height: 40px;
        line-height: 40px;
    }

    .selected {
        border-radius: 6px;
        padding-left: 8px;
        color: #424242;
        cursor: pointer;
        user-select: none;
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        font-size: 14px;
        transition: all 0.25s ease-in-out;
    }

    .selected.open {
        border-radius: 6px 6px 0 0;
        border-bottom: none;
    }

    .selected.open:after {
        transform: rotate(45deg);
    }

    .selected:after {
        width: 7px;
        height: 7px;
        border-right: 1px solid #4a6e78;
        border-bottom: 1px solid #4a6e78;
        position: absolute;
        right: 12px;
        content: " ";
        top: 16px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
    }

    .dividing-line {
        width: 140px;
        color: #424242;
        border-bottom: 1px solid lightgray;
        margin: auto;
    }

    .items {
        color: #ffffff;
        border-radius: 0 0 6px 6px;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 9;
        background: white;
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        transition: all 0.25s ease-in-out;
    }

    .item {
        color: #424242;
        padding-left: 8px;
        cursor: pointer;
        user-select: none;
        font-size: 14px;
    }

    .item:hover {
        background-color: lightskyblue;
    }

    .hide {
        visibility: hidden;
        max-height: 0;
        transition: all 0.25s ease-in-out;

    }

    .show {
        visibility: visible;
        overflow-y: auto;
        transition: all 0.25s ease-in-out;
        max-height: 200px;
    }

    .show::-webkit-scrollbar {
        display: none;
    }


</style>
