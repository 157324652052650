<template>
  <div class="removed">
    <img class="logo" :src="require(`../assets/img/genie/${$store.getters.ext.vert}.svg`)" alt="">

    <div class="wrapper">
      <div class="image">
        <img src="../assets/img/removed-image.svg" alt="">
      </div>

      <div class="content">
        <span class="title">You Decided To Leave.</span>
        <span class="title">We Respect That.</span>


        <h3>Help Us Get Better</h3>

        <div class="button" @click="giveFeedback">
          <span class="icon-button">
             <img :src="require('../assets/img/icon-mail.svg')" alt="">
          </span>
          <span>Give us feedback</span>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Feedback from "@/helpers/feedback";

export default {
  name: "Removed",
  data() {
    return {
      Feedback
    }
  },
  methods: {
    giveFeedback() {
      window.open(Feedback[this.$store.getters.ext.vert]);
    }
  }
}
</script>

<style scoped>

.removed {
  user-select: none;
  padding-top: 14vh;
  text-align: center;
  animation-duration: 1s;
  animation-name: fadeInUp;
}

.removed .logo {
  width: 100px;
}

.wrapper {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;

}

.wrapper .image img {
  width: 445px;
  margin-right: 15px;
}

img {
  max-width: 100%;
}

.content {
  text-align: left;
}

.title {
  font-size: 50px;
  display: block;
  font-weight: 600;
}

.button {
  background: #FFFFFF;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 3rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 20px;
  max-width: 290px;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.button:hover {
  transform: scale(1.1);
}

.icon-button {
  width: 58px;
  height: 58px;
  background: #2FC1EA;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}



@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
</style>